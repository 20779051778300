<template src="./EditProfileLoadPhotoModal.html"></template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "edit-profile-load-photo-modal",
  components: {
    Cropper,
  },
  data() {
    return {
      photo: null,
      photoBase64: "",
      style: { width: "400px" },
      role: "",
      coverPhoto: null,
    };
  },
  props: {
    openModal: { type: Boolean, defaul: false },
  },
  created() {
    this.role = this.$store.state.User.user.role.name;
  },
  methods: {
    imageToBase64() {
      this.style.width = "40vw";
      var reader = new FileReader();
      reader.readAsDataURL(this.photo);
      reader.onload = () => {
        this.photoBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.error("Error on image to base 64:", error);
      };
    },
    cropImage() {
      const result = this.$refs.cropper.getResult().canvas.toDataURL();
      this.$emit("loadFile", result);
    },
    uploadCoverPhoto() {
      this.$emit("uploadCoverPhoto", this.coverPhoto);
    },
  },
  computed: {
    roleValidationUniversity() {
      return ["University"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./EditProfileLoadPhotoModal.scss"></style>
