var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"load-photo-modal"},[_c('b-modal',{attrs:{"active":_vm.openModal,"has-modal-card":"","trap-focus":"","canCancel":false,"destroy-on-hide":false,"aria-role":"dialog","aria-label":"Modal para cargar foto de perfil","aria-modal":""},on:{"update:active":function($event){_vm.openModal=$event}}},[_c('div',{staticClass:"modal-card",style:(_vm.style)},[_c('button',{staticClass:"closeModal",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v("X")]),_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Subir foto de perfil")])]),_c('section',{staticClass:"modal-card-body"},[_c('p',{staticClass:"mb-3"},[_vm._v("Actualiza tu foto para mejorar la presentación de tu perfil profesional.")]),(!_vm.photo)?[_c('b-field',{staticClass:"file is-primary mb-0",class:{'has-name': !!_vm.photo}},[_c('b-upload',{staticClass:"file-label",attrs:{"accept":"image/*"},on:{"input":function($event){return _vm.imageToBase64()}},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}},[_c('span',{staticClass:"file-cta"},[_c('span',{staticClass:"file-label is-flex is-justify-content-center is-fullwidth has-text-weight-semibold"},[_vm._v("Subir foto")])])])],1),(_vm.roleValidationUniversity)?_c('b-field',{staticClass:"file is-primary",class:{'has-name': !!_vm.coverPhoto}},[_c('b-upload',{staticClass:"file-label",attrs:{"accept":"image/*"},on:{"input":_vm.uploadCoverPhoto},model:{value:(_vm.coverPhoto),callback:function ($$v) {_vm.coverPhoto=$$v},expression:"coverPhoto"}},[_c('span',{staticClass:"file-cta"},[_c('span',{staticClass:"file-label is-flex is-justify-content-center is-fullwidth has-text-weight-semibold"},[_vm._v("Subir foto portada")])])])],1):_vm._e()]:_vm._e(),(_vm.photoBase64)?[_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.photoBase64,"stencil-props":{
                        handlers: {},
                        aspectRatio: 1,
                        movable: false,
                        scalable: false,
                    },"resize-image":{
                        adjustStencil: false
                    },"image-restriction":"stencil"}}),_c('span',{staticClass:"file-cta"},[_c('span',{staticClass:"file-label is-flex is-justify-content-center is-fullwidth has-text-weight-semibold",on:{"click":function($event){return _vm.cropImage()}}},[_vm._v("Cargar foto")])])]:_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }